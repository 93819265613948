const likeIcon = (
  <svg
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8022 22.6288L14.8014 22.6292C14.6111 22.7429 14.4169 22.8457 14.2458 22.9172C14.1008 22.9777 14.0231 22.994 14.0023 22.9984C14.0014 22.9986 14.0007 22.9988 14 22.9989C13.9993 22.9988 13.9986 22.9986 13.9977 22.9984C13.9769 22.994 13.8992 22.9777 13.7542 22.9172C13.5831 22.8457 13.3889 22.7429 13.1986 22.6292L13.1985 22.6292C9.39684 20.3586 6.32384 17.9214 4.21191 15.4223C2.09903 12.9221 1 10.4247 1 8.00427C1 3.84727 4.06336 1 7.95455 1C10.318 1 12.0792 2.18925 13.1348 4.01055L13.9903 5.48649L14.8602 4.019C15.9465 2.18644 17.6995 1 20.0593 1C23.9355 1 27 3.84615 27 8.00427C27 10.4254 25.9038 12.9229 23.793 15.4226C21.683 17.9213 18.6104 20.3584 14.8022 22.6288Z"
      stroke="#1F1F2C"
      strokeWidth="2"
    />
  </svg>
)

export default likeIcon
