import React from "react"
import s from "./TermsOfUse.module.css"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export function TermsOfUse() {
  const history = useHistory()

  const handleBack = () => {
    history.goBack()
  }

  return (
    <div className={s.privacyPoliceWrap}>
      <div className="header">
        <button
          type="button"
          className="formBtnBack"
          onClick={handleBack}
        ></button>
        <p>Политика конфиденциальности</p>
      </div>
      <div className={s.privacyPolice}>
        <h1 className="fw-bold fs-5">Пользовательское Соглашение</h1>

        <p className="mt-4 mb-4">
          Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует
          отношения между владельцем bibinto.com (далее Бибинто или
          Администрация) с одной стороны и пользователем сайта с другой. Сайт
          Бибинто не является средством массовой информации. Используя сайт, Вы
          соглашаетесь с условиями данного соглашения.
          <span className="fw-bold">
            Если Вы не согласны с условиями данного соглашения, не используйте
            сайт Бибинто!
          </span>
        </p>

        <h1 className="fw-bold fs-5">Права и обязанности сторон</h1>

        <p className="mt-4 mb-2">
          <span className="fw-bold">Пользователь имеет право:</span> -
          осуществлять поиск информации на сайте - получать информацию на сайте
          - использовать информацию сайта в личных некоммерческих целях.
        </p>
        <p className="mb-2">
          <span className="fw-bold">Администрация имеет право:</span> - по
          своему усмотрению и необходимости создавать, изменять, отменять
          правила - ограничивать доступ к любой информации на сайте.
        </p>

        <p className="mb-2">
          <span className="fw-bold">Пользователь обязуется:</span> - не нарушать
          работоспособность сайта - не использовать скрипты (программы) для
          автоматизированного сбора информации и/или взаимодействия с сайтом и
          его сервисами.
        </p>
        <p className="mb-4">
          <span className="fw-bold">Администрация обязуется:</span> -
          поддерживать работоспособность сайта за исключением случаев, когда это
          невозможно по независящим от Администрации причинам.
        </p>

        <h1 className="fw-bold fs-5">Ответственность сторон</h1>

        <p className="mt-4">
          Администрация не несет никакой ответственности за услуги,
          предоставляемые третьими лицами - в случае возникновения форс-мажорной
          ситуации (боевые действия, чрезвычайное положение, стихийное бедствие
          и т. д.)
        </p>
        <p className="mb-4">
          Администрация не гарантирует сохранность информации, размещённой
          Пользователем, а также бесперебойную работу информационного ресурса
        </p>

        <h1 className="fw-bold fs-5 mb-2">Условия действия Соглашения</h1>
        <p>
          Данное Соглашение вступает в силу при любом использовании данного
          сайта. Соглашение перестает действовать при появлении его новой
          версии. Администрация оставляет за собой право в одностороннем порядке
          изменять данное соглашение по своему усмотрению. Администрация не
          оповещает пользователей об изменении в Соглашении.
        </p>
      </div>
    </div>
  )
}
