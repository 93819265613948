const chatIconLight = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 11.5939 1.67563 13.0866 2.41903 14.3725C2.50371 14.519 2.52538 14.6935 2.4791 14.8563L1.42091 18.5774L5.14122 17.5194C5.30403 17.4731 5.47864 17.4949 5.62515 17.5796C6.91159 18.3238 8.40508 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C8.27517 20 6.65075 19.5628 5.23325 18.7928L1.11546 19.9638C0.456098 20.1513 -0.153014 19.5422 0.0344874 18.8829L1.20574 14.7641C0.436655 13.3472 0 11.7238 0 10ZM6 8.125C6 7.77982 6.27982 7.5 6.625 7.5H13.375C13.7202 7.5 14 7.77982 14 8.125C14 8.47018 13.7202 8.75 13.375 8.75H6.625C6.27982 8.75 6 8.47018 6 8.125ZM6.625 11.25C6.27982 11.25 6 11.5298 6 11.875C6 12.2202 6.27982 12.5 6.625 12.5H11.375C11.7202 12.5 12 12.2202 12 11.875C12 11.5298 11.7202 11.25 11.375 11.25H6.625Z"
      fill="white"
    />
  </svg>
)

export default chatIconLight
