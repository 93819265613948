const IconSettingsMob = (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.05203 1.21704C7.72639 0.507111 8.81982 0.507076 9.49422 1.21696C10.1686 1.92679 10.1686 3.07763 9.49429 3.7875L8.94278 4.3681L6.50066 1.79748L7.05203 1.21704ZM6.05872 2.26274L1.00946 7.57831C0.81902 7.77879 0.683308 8.0295 0.616542 8.30416L0.00976086 10.8004C-0.0176002 10.9129 0.0134746 11.0323 0.0915129 11.1145C0.169551 11.1967 0.282978 11.2294 0.389918 11.2006L2.76166 10.5626C3.02282 10.4923 3.26121 10.3493 3.4518 10.1487L8.50084 4.83335L6.05872 2.26274Z"
      fill="#76768C"
    />
  </svg>
)

export default IconSettingsMob
